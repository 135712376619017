import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../common/atoms/primarybutton";
import Loader from "../../../common/loader";
import { deleteCms, getCms } from "../../../classes/cmsApi";
import { StateContext } from "../../../context";
import { getDownloadLinks } from "../api/api";
import { BasicInfo, DataFormatBox, DataInfo, DataProviderInfo, FileBox, InfoContainer } from "./formitems";
import { getDefVal, saveDataSet } from "./helpers";
import Prompt from "./prompt";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import CheckBox from "../../../common/checkbox";
import { usr as user } from "../../../classes/user";
import { translateValue } from "../../card/card";
import { generateRandomKey } from "../../../helpers/generateRandomKey";
import ModalComponent from "../../../modals/modal";
import { modelIsDatex, syntaxIsXML } from "./formitems/dataformat";
import { SampleFileBox } from "./formitems/filebox";

export const translateValues = (field, value = true) => {
  return field;
  return field?.map((item) => {
    return {
      key: item.key,
      value: translateValue(value ? item.value : item.key),
    };
  });
};

const emptyOptions = {
  basicInfo: {
    metadatalanguages: [],
    serviceType: [],
  },
  dataProviderInfo: {},
  dataInfo: {
    languages: [],
    resourceTypes: [],
    categories: [],
    coordinateSystem: [],
    geocoverage_nuts: [],
    updateFrequency: [],
    transportNetworkCoverage: [],
    transportModeCoverage: [],
    interfaces: [],
    licenceTypes: [],
    quality_level: [],
  },
  licence: {},
  dataFormat: {
    dataFormatModel: [],
    dataModelSyntax: [],
  },
  schemas: {},
};

const ConfirmDeleteModal = ({ deleteCallback }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const { handleModalClose } = useContext(StateContext);
  const [checked, setChecked] = useState(false);
  return (
    <ModalComponent
      id="delete-dataset-modal"
      className="authorize deleteprofile"
      overlayClassName="authoverlay"
      style={{ width: `50%` }}
    >
      <form onSubmit={handleSubmit(deleteCallback)}>
        {/* header */}
        <div className="authorize-header">
          <span className="authorize-header-title">
            <Trans i18nKey={"areYouSureYouWantToUnpublish"} />
          </span>
          <a
            href="#close"
            style={{ textDecoration: "none" }}
            onClick={() => handleModalClose("delete-dataset-modal")}
          >
            <img alt="alt" className="authorize-header-icon" src="/assets/img/cancel4.svg" />
          </a>
        </div>

        {/* body */}
        <div className="authorize-main-left-btngroup">
          <textarea
            {...register("textarea", {
              required: { message: "fieldIsRequired", value: true },
            })}
            className={`overall-input-big ${errors.textarea && "red-outline"}`}
            placeholder={t("pleaseSpecifyReasonForUnpublishing")}
          />
        </div>
        <CheckBox
          style={{ margin: "1em 0" }}
          checked={checked}
          onClick={() => setChecked(!checked)}
          text={t("awareTheDatasetWillBeDeleted")}
        />

        {/* button */}
        <div style={{ display: `flex`, width: `100%` }}>
          <button
            className={`btn-danger ${(!checked || !isValid) && "btn-disabled"}`}
            style={{ height: "auto" }}
            type="submit"
            disabled={!checked}
          >
            <span className="authorize-main-left-reg-text">
              <Trans i18nKey="delete" />
            </span>
          </button>
        </div>
      </form>
    </ModalComponent>
  );
};

// for populating dropdowns with data from CMS
const getDropdownValues = async (language) => {
  try {
    const respCategories = await getCms("public/data-categories", language);

    const removeDuplicates = (arr, key) => {
      const seen = new Set();
      return arr.filter((item) => {
        const value = item[key][0].value;
        if (seen.has(value)) {
          return false;
        } else {
          seen.add(value);
          return true;
        }
      });
    };

    const uniqueCategories = removeDuplicates(respCategories, "tid");

    const buildHierarchy = (terms) => {
      const hierarchy = [];

      terms.forEach((term) => {
        if (!term.parent[0].target_uuid) {
          const parentTerm = {
            key: term.tid[0].value,
            value: term.name[0].value,
            children: [],
          };

          terms.forEach((innerTerm) => {
            if (innerTerm.parent[0].target_id === parentTerm.key) {
              const childTerm = {
                key: innerTerm.tid[0].value,
                value: innerTerm.name[0].value,
              };
              parentTerm.children.push(childTerm);
            }
          });

          hierarchy.push(parentTerm);
        }
      });

      return hierarchy;
    };

    let nestedTaxonomy = buildHierarchy(uniqueCategories);

    const resp = await getCms("public/metadata-dropdown/list", language);
    const publicationTypesResp = await getCms("public/metadata-dropdown/list");

    const data = {
      basicInfo: {
        serviceType: translateValues(resp.field_service_type ?? []),
        status: translateValues(resp.field_data_set_statuss ?? []),
        visibility: translateValues(resp.field_dataset_visibility ?? []),
      },
      dataProviderInfo: {},
      dataInfo: {
        languages:
          resp.field_metadata_language === "null" ? [] : translateValues(resp.field_metadata_language) ?? [],
        resourceTypes: resp.field_resource_type ?? [],
        categories: nestedTaxonomy,
        typesAccordingToEURegulation: translateValues(resp.datu_veids_atbilstosi_es_regulai ?? []),
        publicationTypes: translateValues(publicationTypesResp.field_datex_publication_type ?? []),
        coordinateSystem: translateValues(resp.field_coordinate_system ?? []),
        georeferencingMethod: translateValues(resp.field_georeferencing_method ?? []), // TODO
        geocoverage_nuts: translateValues(resp.term_field_geographical_coverage ?? []),
        updateFrequency: translateValues(resp.field_update_frequency, true) ?? [],
        transportNetworkCoverage: translateValues(resp.field_transport_network_coverage, true) ?? [],
        transportModeCoverage: translateValues(resp.field_transport_mode_coverage, true) ?? [],
        interfaces: resp.field_access_interface ?? [],
        licenceTypes: translateValues(resp.field_licence_conditions, true) ?? [],
        quality_level: translateValues(resp.field_quality_level, false) ?? [],
        esRegulas: translateValues(resp.regula ?? []),
        default_geocoverage_term: resp.term_field_geographical_coverage.filter((item) => item?.isDefault)[0],
      },
      licence: {},
      dataFormat: {
        dataFormatModel: translateValues(resp.term_data_format_model ?? []),
        dataModelSyntax: translateValues(resp.field_field_data_format_syntax ?? []),
      },
      datexModelTerms: resp.term_data_format_model.filter((item) => item?.isDatex).map((item) => item.key),
      schemas: resp.term_data_format_model.reduce((acc, item) => {
        acc[item.key] = item.schemas ?? [];
        return acc;
      }, {}),
    };
    return data;
  } catch (err) {
    console.error(err);
    return emptyOptions;
  }
};

const textWithLink = (navigate, textStart, linkText, textEnd, link) => {
  return (
    <>
      <br />
      {t(textStart)}
      <span
        style={{
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={(e) => navigate(link)}
        onKeyDown={(e) => {
          if (e.key === "Enter") navigate(link);
        }}
        tabIndex={0}
        className="focus-blue"
      >
        {t(linkText)}
      </span>
      {t(textEnd)}
    </>
  );
};


const OverAllData = ({ checkboxState, extraData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedSyntax, setSelectedSyntax] = useState([]);
  const [resetSelectedSyntax, setResetSelectedSyntax] = useState(false);
  const [files, setFiles] = useState([]);
  const [sampleFile, setSampleFile] = useState(null);
  const [sampleFileChanged, setSampleFileChanged] = useState(false);

  const [datasetDescriptionFileLatvian, setDatasetDescriptionFileLatvian] = useState(null);
  const [datasetDescriptionFileLatvianChanged, setDatasetDescriptionFileLatvianChanged] = useState(false);
  const [datasetDescriptionFileEnglish, setDatasetDescriptionFileEnglish] = useState(null);
  const [datasetDescriptionFileEnglishChanged, setDatasetDescriptionFileEnglishChanged] = useState(false);

  const [descriptionFileErrors, setDescriptionFileErrors] = useState({});

  const [filesLoading, setFilesLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayErrorFile, setDisplayErrorFile] = useState(false);
  const [tempDataSet, setTempDataSet] = useState(null);

  const { language, handleModalOpen } = useContext(StateContext);
  const { declarations, isNew, copy, formData } = extraData;

  const [type, setType] = useState("");
  const defval = useMemo(
    () => tempDataSet ?? getDefVal(copy, extraData, user, isNew),
    [copy, extraData, isNew, tempDataSet]
  );

  const [selectedModel, setSelectedModel] = useState(defval?.format_model ?? "");

  const {
    register,
    getValues,
    getFieldState,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    trigger,
    unregister,
  } = useForm({
    mode: "all",
    defaultValues: defval,
  });

  const { data: optionData } = useQuery({
    queryKey: ["dataset-dropdownValues", language],
    queryFn: () => getDropdownValues(language),
    placeholderData: emptyOptions,
    refetchOnWindowFocus: false,
  });

  const getSampleFile = useCallback(
    async (file) => {
      if (!file) return;

      setSampleFile(
        Array.isArray(extraData.formData.field_data_sample)
          ? extraData.formData.field_data_sample[0]
          : extraData.formData.field_data_sample ?? {}
      );
    },
    [extraData.formData.field_data_sample]
  );

  const getDatasetDescriptionFiles = useCallback(
    async (lang) => {
      const latvianFileContents = extraData.formData.dataset_desc_file_lv ?? {};
      const englishFileContents = extraData.formData.dataset_desc_file ?? {};

      if (latvianFileContents) {
        setDatasetDescriptionFileLatvian({
          ...latvianFileContents,
          path: latvianFileContents.path,
          can_restore: false,
          // text: extraData.formData.desc_file_name_lv,
          text: "TEST STIRNG",
          datetime: extraData.formData.desc_file_lv_datetime,
          index: 0,
          custom_index: "descriptionFileLatvian",
        });
      }
      if (englishFileContents) {
        setDatasetDescriptionFileEnglish({
          ...englishFileContents,
          path: englishFileContents.path,
          can_restore: false,
          text: extraData.formData.desc_file_name, // using this field as file name
          datetime: extraData.formData.desc_file_datetime,
          index: 1,
          custom_index: "descriptionFileEnglish",
        });
      }
    },
    [extraData]
  );

  const getFiles = useCallback(async (files, nid) => {
    if (!files) return;

    try {
      setFilesLoading(true);

      files = await Promise.all(files.map(async (file) => await getDownloadLinks(file, nid)));
      files = files.map((file, index) => ({
        target_id: file.target_id,
        file_id: file.id,
        text: file.file_title,
        text_en: file.file_title_en ?? "",
        datetime: file.file_datetime,
        path: file.path,
        file: null,
        index,
        error: file.error,
        key: generateRandomKey(),
        can_restore: true,
      }));
      // add new file as empty object
      if (files.length === 0) {
        files.push({
          index: files.length,
          target_id: null,
          file_id: null,
          text: "",
          text_en: "",
          datetime: "",
          can_restore: false,
        });
      }
      setFiles(files);
    } catch (err) {
      console.error(err);
    } finally {
      setFilesLoading(false);
    }
  }, []);

  const handleFileValidationErrors = useCallback(
    (errorArray) => {
      const errors = [];
      for (const error of errorArray) {
        const file = files.find((file) => error.includes(file.name));
        const oldFileErrors = fileErrors[file.index] ?? [];
        errors[file.index] = [...oldFileErrors, "datasetErrorFailedValidation"];
      }
      setFileErrors((prev) => ({ ...prev, ...errors }));
      setDisplayErrorFile(true);
    },
    [fileErrors, files]
  );

  const hasValidationErrors = (errors) => {
    for (const [_key, value] of Object.entries(errors)) {
      if (value.includes("datasetErrorFailedValidation")) {
        return true;
      }
    }
    return false;
  }

  const removeValidationErrors = useCallback(() => {
    setFileErrors((current) => {
      const newErrors = { ...current };
      for (const [key, value] of Object.entries(newErrors)) {
        if (value === "datasetErrorFailedValidation") {
          delete newErrors[key];
        }
      }
      return newErrors;
    });
  }, []);

  const saveData = useCallback(async () => {
    try {
      setLoading(true);
      await saveDataSet(
        getValues(),
        files,
        sampleFile,
        sampleFileChanged,
        datasetDescriptionFileLatvian,
        datasetDescriptionFileEnglish,
        datasetDescriptionFileLatvianChanged,
        datasetDescriptionFileEnglishChanged,
        type,
        isNew,
        optionData.dataInfo.default_geocoverage_term
      );
      navigate("/manaskopas/list");
    } catch (err) {
      if (err?.error?.validationErrors) {
        handleFileValidationErrors(err?.error?.validationErrors);
      } else {
        setDisplayError(t("failedToSaveData"));
      }
    }
    setLoading(false);
  }, [
    getValues,
    files,
    sampleFile,
    sampleFileChanged,
    datasetDescriptionFileLatvian,
    datasetDescriptionFileEnglish,
    datasetDescriptionFileLatvianChanged,
    datasetDescriptionFileEnglishChanged,
    type,
    isNew,
    optionData.dataInfo.default_geocoverage_term,
    navigate,
    handleFileValidationErrors,
    t,
  ]);

  const onSubmit = useCallback(async () => {

    const fields = getValues();

    console.log("fields", fields);

    const shouldCheckPublicationType = modelIsDatex(selectedModel, optionData?.datexModelTerms);

    const shouldCheckSchema = syntaxIsXML(selectedSyntax);

    const fieldsToCheck = Object.keys(fields).filter((field) => {
      if (field === "datex_publication_type" && !shouldCheckPublicationType) {
        return false;
      }
      if (field === "data_format_schema" && !shouldCheckSchema) {
        return false;
      }
      return true;
    });

    const isValid = fieldsToCheck.length === 0 ? false : await trigger(fieldsToCheck);
    // const isValidFiles = 

    if (!isValid) {
      setDisplayError(true);

      // smooth scroll to errors
      const firstErrorKey = Object.keys(errors)[0];
      const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }

      return;
    }
    
    await saveData();

  }, [getValues, selectedModel, optionData?.datexModelTerms, selectedSyntax, trigger, saveData, errors]);

  const handleCopy = useCallback(() => {
    navigate("/manaskopas/new", {
      state: {
        ...extraData,
        formData: { ...extraData?.formData, nid: null },
        isNew: true,
      },
    });
  }, [extraData, navigate]);

  const deleteDataSet = useCallback(
    async ({ textarea }) => {
      const res = await deleteCms("api/v1/delete/metadata", { nid: formData.nid, reason: textarea });
      if (res.message === "ok") {
        navigate("/manaskopas/list", {
          state: { deleted: true },
        });
      } else {
        setDisplayError("Neizdevās dzēst datu kopu");
      }
    },
    [formData.nid, navigate]
  );

  useEffect(() => {
    if (!Object.keys(fileErrors).length) {
      setDisplayErrorFile(false);
    }
  }, [fileErrors]);

  useEffect(() => {
    if (extraData?.formData?.field_data_sample) {
      getSampleFile(extraData.formData.field_data_sample);
    }

    getDatasetDescriptionFiles(extraData.formData.dataset_desc_file);

    if (extraData?.formData?.download_url?.length && !isNew) {
      getFiles(extraData?.formData?.download_url, extraData?.formData?.nid);
    }
  }, [
    extraData.formData?.download_url,
    extraData.formData.field_data_sample,
    extraData.formData.dataset_desc_file,
    extraData.formData?.nid,
    getFiles,
    getSampleFile,
    getDatasetDescriptionFiles,
    isNew,
  ]);

  useEffect(() => {
    setType(
      extraData?.formData?.distribution_mode ||
        defval?.distribution_mode ||
        (checkboxState.check1 ? "file" : "api")
    );
  }, [extraData?.formData?.distribution_mode, defval?.distribution_mode, checkboxState, isNew]);

  useEffect(() => {

    if (defval?.field_data_format_syntax) {
      setSelectedSyntax(defval.field_data_format_syntax ?? []);
    }
  }, [defval]);

  const inputProps = {
    defval,
    trigger,
    getValues,
    register,
    getFieldState,
    setValue,
    errors,
    unregister,
    isNew,
    checkboxState,
  };

  return (
    <div className="overall-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
          <div className="overall-title">
            <Trans i18nKey="datasetOverall" />
          </div>
          {!isNew && <InfoContainer {...inputProps} {...optionData.basicInfo} />}
          <BasicInfo
            {...inputProps}
            {...optionData.basicInfo}
            fileErrors={fileErrors}
            setFileErrors={setFileErrors}
            selectedSyntax={selectedSyntax}
            setSampleFile={setSampleFile}
            setSampleFileChanged={setSampleFileChanged}
            setDisplayError={setDisplayError}
            languages={optionData.dataInfo.languages}
          />
          <SampleFileBox
            sampleFile={datasetDescriptionFileLatvian}
            setSampleFile={setDatasetDescriptionFileLatvian}
            setSampleFileChanged={setDatasetDescriptionFileLatvianChanged}
            selectedSyntax={["txt", "docx", "pdf"]}
            fileErrors={fileErrors}
            setFileErrors={setFileErrors}
            customId="descriptionFileLatvian"
            customInputTitle="fileDescriptionLatvian"
          />
          <SampleFileBox
            sampleFile={datasetDescriptionFileEnglish}
            setSampleFile={setDatasetDescriptionFileEnglish}
            setSampleFileChanged={setDatasetDescriptionFileEnglishChanged}
            selectedSyntax={["txt", "docx", "pdf"]}
            fileErrors={fileErrors}
            setFileErrors={setFileErrors}
            customId="descriptionFileEnglish"
            customInputTitle="fileDescriptionEnglish"
          />

          <SampleFileBox
            sampleFile={sampleFile}
            setSampleFile={setSampleFile}
            setSampleFileChanged={setSampleFileChanged}
            selectedSyntax={selectedSyntax}
            fileErrors={fileErrors}
            setFileErrors={setFileErrors}
          />

          <div className="overall-title">
            <Trans i18nKey="datasetAddInfoAboutDataGiver" />
          </div>
          <DataProviderInfo declarations={declarations} {...inputProps} {...optionData.dataProviderInfo} />

          <div className="overall-title">
            <Trans i18nKey="datasetAddInfoAboutData" />
          </div>
          <DataInfo
            type={type}
            {...inputProps}
            {...optionData.dataInfo}
            {...optionData.basicInfo}
            selectedModel={selectedModel}
            datexModelTerms={optionData?.datexModelTerms ?? []}
          />
          <DataFormatBox
            {...inputProps}
            {...optionData.dataFormat}
            setFiles={setFiles}
            publicationTypes={optionData.dataInfo.publicationTypes}
            selectedSyntax={selectedSyntax}
            setSelectedSyntax={setSelectedSyntax}
            resetSelectedSyntax={resetSelectedSyntax}
            setResetSelectedSyntax={setResetSelectedSyntax}
            removeValidationErrors={removeValidationErrors}
            datexModelTerms={optionData?.datexModelTerms ?? []}
            schemas={optionData?.schemas ?? {}}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
          />
          {type === "file" && !isNew && (
            <FileBox
              loading={filesLoading}
              files={files}
              setFiles={setFiles}
              register={register}
              {...inputProps}
              fileErrors={fileErrors}
              selectedSyntax={selectedSyntax}
              getValue={getValues}
              resetSelectedSyntax={resetSelectedSyntax}
              setSelectedSyntax={setSelectedSyntax}
              setFileErrors={setFileErrors}
            />
          )}
          {type === "file" && isNew && (
            <div className="register-info-right-rules-note">{t("canAddFilesLater")}</div>
          )}
        </div>
      </form>
      <Prompt
        when={(isDirty || tempDataSet) && !loading}
        saveTempDataSet={() => setTempDataSet(getValues())}
        removeTempData={() => setTempDataSet(null)}
        message="Are you sure you want to leave?"
      />
      {(displayError || displayErrorFile) && (
        <div className="authorize-main-left-alert" style={{ marginBottom: 10, marginTop: 10 }}>
          <div className="authorize-main-left-alert-text">
            {Object.keys(fileErrors).length > 0
              ? Object.keys(fileErrors).map((index) => (
                  <div key={index}>
                    {`${t("errorInFile")} "${
                      files ? (files[index] ? files[index].name : sampleFile.name) : ""
                    }": ${fileErrors[index]?.map((error) => t(error)).join(", ")}`}
                  </div>
                ))
              : displayError.length
              ? displayError
              : t("pleaseFillAllFields")}

            {/* if atleast one "datasetErrorFailedValidation" error */}
            {hasValidationErrors(fileErrors) && (
              <>
                {textWithLink(
                  navigate,
                  "datasetErrorFailedValidationLookHereStart",
                  "datasetErrorFailedValidationLookHereLink",
                  "datasetErrorFailedValidationLookHereEnd",
                  "/messages"
                )}
                {textWithLink(
                  navigate,
                  "datasetErrorFailedCheckStandartsInHelpStart",
                  "datasetErrorFailedCheckStandartsInHelpLink",
                  "datasetErrorFailedCheckStandartsInHelpEnd",
                  language === "lv"
                    ? "/palidziba?searchWord=Mobilitātes datu standarti"
                    : "/palidziba?searchWord=Data formats"
                )}
              </>
            )}
          </div>
          <img
            onClick={() => setDisplayError(false)}
            alt="alt"
            className="authorize-main-left-alert-icon"
            src="/assets/img/cancel3.svg"
          />
        </div>
      )}
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="overall-sub-box" style={{ alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div onClick={() => navigate(-1)}>
                <span
                  className="manaskopas-main-tb1-tr2-td3-right-part2-font2 text-red focus-red"
                  style={{ marginRight: 20 }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") navigate(-1);
                  }}
                >
                  <Trans i18nKey="cancel" />
                </span>
              </div>

              <PrimaryButton
                disabled={Object.keys(fileErrors).length > 0}
                onClick={onSubmit}
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  height: 64,
                  padding: 0,
                  paddingLeft: 31,
                  paddingRight: 31,
                  minWidth: 146,
                }}
                title={t("datasetAddSave")}
                hideSvg
              />
            </div>
            {!isNew && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "fit-content",
                  gap: 20,
                }}
              >
                <div
                  onClick={() => handleModalOpen("delete-dataset-modal")}
                  className="delete-button-container focus-red"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleModalOpen("delete-dataset-modal");
                  }}
                >
                  <img
                    alt="alt"
                    className="manaskopas-main-tb1-tr2-td3-right-part2-sub2"
                    src="/assets/img/bin.svg"
                  />
                  <span className="manaskopas-main-tb1-tr2-td3-right-part2-font2">
                    <Trans i18nKey="datasetAddDontPublishAnymore" />
                  </span>
                </div>

                <div
                  className="manaskopas-main-tb1-tr2-td3-right-part1 focus-red"
                  style={{ marginRight: 20, textDecoration: "underline" }}
                  onClick={() => {
                    handleCopy();
                  }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleCopy();
                  }}
                >
                  <span className="manaskopas-main-tb1-tr2-td3-right-part2-font2 make-a-copy-span">
                    <Trans i18nKey="datasetAddMakeACopy" />
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ConfirmDeleteModal deleteCallback={deleteDataSet} />
    </div>
  );
};

export default OverAllData;
