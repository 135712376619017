import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getCms } from "../../classes/cmsApi";
import { Banner, CatalogSearch } from "../../common";
import { AccordItem } from "./components";
import { useTranslation } from "react-i18next";
import { StateContext } from "../../context";
import { useParams, useSearchParams } from "react-router-dom";
import { usr as user } from "../../classes/user";
import useSuperDuperReliableSmoothScroll from "../../hooks/useSuperDuperReliableSmoothScroll";
import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, ReloadPageButton } from "../manaskopas/components/errormessage";
import Loader from "../../common/loader";
import { removeLatvianDiacritics, convertToRGBA } from "../search/search";
import { colors } from "../../helpers/contrast";

export const highlightKeywordHelp = (words, keyword, foregroundColor, backgroundColor) => {
  if (!keyword) return words;

  const cleanKeyword = removeLatvianDiacritics(keyword).toLowerCase();
  const cleanWords = removeLatvianDiacritics(words).toLowerCase();

  let highlightedHTML = "";
  let i = 0;
  let insideTag = false; // Track if we're inside an HTML tag

  while (i < words.length) {
    if (words[i] === "<") {
      // Skip HTML tags
      insideTag = true;
      highlightedHTML += words[i];
    } else if (words[i] === ">") {
      insideTag = false;
      highlightedHTML += words[i];
    } else if (!insideTag) {
      const originalSubstr = words.slice(i, i + keyword.length);
      const cleanSubstr = cleanWords.slice(i, i + keyword.length);
      if (cleanSubstr === cleanKeyword) {
        highlightedHTML += `<span style="background-color:${convertToRGBA(
          foregroundColor,
          0.7
        )}; color:rgba(${backgroundColor}, 0.4);">${originalSubstr}</span>`;
        i += keyword.length;
        continue;
      } else {
        highlightedHTML += words[i];
      }
    } else {
      highlightedHTML += words[i];
    }
    i++;
  }

  return highlightedHTML;
};

const Accordination = ({ helpPages, tabOpened, helpOpened, setHelpOpened, setTabOpened }) => {
  return helpPages.map((item) => (
    <AccordItem
      key={item.id}
      item={item}
      tabOpened={tabOpened}
      helpOpened={helpOpened}
      setHelpOpened={setHelpOpened}
      onMenuItemClick={() => {
        // main accordion title click
        setTabOpened(tabOpened === item.id ? 0 : item.id);
      }}
    />
  ));
};

const Help = () => {
  const { nid } = useParams();
  const { t } = useTranslation();
  const [tabOpened, setTabOpened] = useState(0);
  const [helpOpened, setHelpOpened] = useState(Number(nid) || 0);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedBody, setSelectedBody] = useState("");

  const [helpPagesMap, setHelpPagesMap] = useState({});

  const { language, contrast } = useContext(StateContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const dashboardBanner = {
    path2: t("menuProfileFeatures"),
    path2Url: "/dashboard",
  };
  const bannerData = {
    title: t("help"),
    path1: t("workspaceStart"),
    path1Url: "/",
    ...(user.sub ? dashboardBanner : {}),
    path3: t("help"),
  };

  const handleSearchWordChange = useCallback(
    async (searchWord) => {
      setSearchParams({ searchWord });
    },
    [setSearchParams]
  );

  const { foregroundColor, backgroundColor } = colors[contrast] || colors["contrast_blue"];

  /* this function returns subPage (subcategory in drupal terms) that either:
      matches searchParams.get("searchWord") 
      or is mentioned in the body of the help page.

    function expects helpPagesMap to be already populated
    
    function always returns first found subPage that matches the search word
  */
  const findSubPageFromSearchWord = useCallback(() => {
    if (!searchParams.get("searchWord")) return "";
    if (!helpPagesMap) return "";

    const subPagesWithKeywordInTitle = Object.keys(helpPagesMap).reduce((acc, category) => {
      helpPagesMap[category].forEach((item) => {
        const matchingSubcategories = item.title?.filter((subItem) =>
          removeLatvianDiacritics(subItem.value.toLowerCase()).includes(
            removeLatvianDiacritics(searchParams.get("searchWord").toLowerCase())
          )
        );

        if (matchingSubcategories && matchingSubcategories.length > 0) {
          acc.push(...matchingSubcategories.map((sub) => sub.value));
        }
      });

      return acc;
    }, []);

    if (subPagesWithKeywordInTitle.length > 0) {
      return subPagesWithKeywordInTitle[0];
    }

    const stripHtmlTags = (str) => {
      return str.replace(/<[^>]*>/g, "");
    };

    const subPagesWithKeywordInBody = Object.keys(helpPagesMap).reduce((acc, category) => {
      helpPagesMap[category].forEach((item) => {
        const processedText = stripHtmlTags(item.body?.[0]?.processed || "");
        if (processedText.toLowerCase().includes(searchParams.get("searchWord").toLowerCase())) {
          const matchingSubcategories = item.title?.map((subItem) => subItem.value);
          if (matchingSubcategories && matchingSubcategories.length > 0) {
            acc.push(...matchingSubcategories);
          }
        }
      });
      return acc;
    }, []);

    return subPagesWithKeywordInBody.length > 0 ? subPagesWithKeywordInBody[0] : "";
  }, [helpPagesMap, searchParams]);

  const switchPage = useCallback(
    (nid) => {
      helpPagesMap &&
        Object.keys(helpPagesMap).forEach((category, index) => {
          helpPagesMap[category].forEach((item) => {
            if (helpOpened === 0 && item?.field_noklusejuma_lapa[0]?.value === true && !nid) {
              setHelpOpened(item.nid[0]?.value);
              setSelectedTitle(item.title[0]?.value);
              setSelectedBody(item.body[0]?.processed);
              setTabOpened(index + 1);
              return;
            }

            // check nid
            if (item.nid[0]?.value === Number(nid)) {
              setSelectedTitle(item.title[0]?.value);
              setSelectedBody(item.body[0]?.processed);
            }
            // check keyword in title
            const subPage = findSubPageFromSearchWord();

            const category = item.field_pamatkategorijas_nosaukums[0].value;
            const title = item.title[0].value;

            if (category === t(searchParams.get("searchWord")) || title === t(subPage)) {
              setHelpOpened(item.nid[0]?.value);
            }
            if (item.nid[0]?.value === helpOpened || item.nid[0]?.value === Number(nid)) {
              setSelectedTitle(item.title[0]?.value);
              setSelectedBody(item.body[0]?.processed);
              const index = Object.keys(helpPagesMap).indexOf(category);
              setTabOpened(index > -1 ? index + 1 : Object.keys(helpPagesMap).length + 1);
            }
            return;
          });
        });
    },
    [helpPagesMap, helpOpened, findSubPageFromSearchWord, searchParams, t]
  );

  useEffect(() => {
    switchPage(nid);
  }, [nid, switchPage, searchParams]);

  const extractOrderingLevels = useCallback((pagesOrderResponse) => {
    // This function processes the pagesOrderResponse to create three separate maps for each level.
    // Since node IDs are not unique across different levels, we need to distinguish which level each node ID belongs to.
    // This is necessary because the node IDs are the only identifiers provided by the Drupal endpoint.

    // Also important to note that UUIDs are this endpoint specific and are not seen anywhere else in the codebase.
    // Drupal backend developers failed to create consistent API! :)

    // After maps are created, we can now use pagesResponse's node IDs to get the correct ordering for each level.

    const level1UUIDs = [];
    const level2UUIDs = [];

    const level1Map = {};
    const level2Map = {};
    const level2MapTitles = {};
    const level3Map = {};

    // we can only populate each level once we know all of the UUIDs for the previous level

    // populate level 1 UUIDs and map
    pagesOrderResponse.forEach((page) => {
      const nodeId = page.nid;
      const UUID = page.uuid;
      const parent = page.parent?.split(":").pop() ?? "";
      const weight = page.weight;
      const title = page.title;

      if (parent === "") {
        level1UUIDs.push(UUID);

        // given that level 1 elements do NOT actually exist as objects in drupal we have to use the titles as keys
        level1Map[title] = weight;
      }
    });

    // populate level 2 UUIDs and map
    pagesOrderResponse.forEach((page) => {
      const nodeId = page.nid;
      const UUID = page.uuid;
      const parent = page.parent?.split(":").pop();
      const weight = page.weight;
      const title = page.title;

      const isLevel1 = page.parent === "";
      const isLevel2 = !isLevel1 && level1UUIDs.includes(parent);

      console.log(title, isLevel2, level1UUIDs, parent);

      if (isLevel2) {
        level2UUIDs.push(UUID);
        level2Map[nodeId] = weight;

        // pages that have sub children have incorrect node Ids pointing to them,
        // so we have to use the titles as keys for some level 2 elements
        level2MapTitles[title] = weight;
      }
    });

    // populate level 3 UUIDs and map
    pagesOrderResponse.forEach((page) => {
      const nodeId = page.nid;
      const parent = page.parent?.split(":").pop();
      const weight = page.weight;

      const isLevel1 = page.parent === "";
      const isLevel2 = !isLevel1 && level1UUIDs.includes(parent);
      const isLevel3 = !isLevel1 && !isLevel2 && level2UUIDs.includes(parent);

      if (isLevel3) {
        level3Map[nodeId] = weight;
      }
    });

    return { level1Map, level2Map, level2MapTitles, level3Map };
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const pagesResponse = await getCms("public/help-pages", language);

      const pagesOrderResponse = await fetch(`${(process.env.REACT_APP_CMS_API ?? "/")}api/v1/content/lv/api/help_pages_menu?lang=${language}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => response.json());

      console.log("pagesOrderResponse", pagesOrderResponse);

      const { level1Map, level2Map, level2MapTitles, level3Map } = extractOrderingLevels(pagesOrderResponse);

      const mapping = {};
      for (const mainPage of pagesResponse) {
        const category = mainPage.field_pamatkategorijas_nosaukums[0].value;

        // here we filter out the main pages that are not in the ordering response and as such are considered disabled
        
        // FIRST LEVEL FILTERING
        if (!level1Map[category]) {
          console.log("category", category);
          continue;
        }

        // SECOND LEVEL FILTERING
        if (mainPage.field_apakskategorijas_nosaukums?.length === 0) { 
          if (!level2Map[mainPage.nid[0]?.value] && !level2MapTitles[mainPage.title[0]?.value]) {
            continue;
          }
        }

        // THIRD LEVEL FILTERING
        if (mainPage.field_apakskategorijas_nosaukums?.length > 0) {
          if (!level3Map[mainPage.nid[0]?.value]) {
            continue;
          }
        }

        if (!mapping[category]) {
          mapping[category] = [];
        }
        mapping[category].push(mainPage);
      }

      setHelpPagesMap(mapping);

      const accordData = Object.keys(mapping).map((category, index) => {
        const dataMapping = {};
        let reorder = false;
        mapping[category].forEach((item) => {
          const title = item.field_palidzibas_lapas_nosaukums[0]?.value;
          const nodeId = item.nid[0]?.value;


          if (!dataMapping[title]) {
            dataMapping[title] = {
              title,
              // weight: level2Map[nodeId] ?? level2MapTitles[title],
              sub: [],
            };
          }
          if (item?.field_noklusejuma_lapa[0]?.value === true) {
            dataMapping[title].default = true;
            reorder = true;
          }
          if (item.field_apakskategorijas_nosaukums?.length > 0) {
            item.field_apakskategorijas_nosaukums.forEach((sub) => {
              dataMapping[title].sub.push({
                title: sub.value,
                pageTitle: item.title[0]?.value,
                pageBody: item.body[0]?.processed,
                nid: nodeId,
                // weight: level3Map[nodeId],
              });
            });
          } else {
            dataMapping[title].pageTitle = item.title[0]?.value;
            dataMapping[title].pageBody = item.body[0]?.processed;
            dataMapping[title].nid = nodeId;
            // dataMapping[title].weight = level2Map[nodeId] ?? level2MapTitles[title];
          }
        });

        // sort third level elements
        Object.values(dataMapping).forEach((item) => {
          if (item.sub.length > 0) {
            item.sub.sort((a, b) => {
              const aWeight = level3Map[a.nid];
              const bWeight = level3Map[b.nid];
              if (aWeight === undefined) return 1;
              if (bWeight === undefined) return -1;
              return parseFloat(aWeight) - parseFloat(bWeight);
            });
          }
        });

        const data = Object.values(dataMapping);

        // sort second level elements
        data.sort((a, b) => {
          const aWeight = level2Map[a.nid] ?? level2MapTitles[a.title];
          const bWeight = level2Map[b.nid] ?? level2MapTitles[b.title];
          if (aWeight === undefined) return 1;
          if (bWeight === undefined) return -1;
          return parseFloat(aWeight) - parseFloat(bWeight);
        });
        

        // make sure the default page is the first one after sorting
        if (reorder) {
          const defaultPage = data.find((item) => item.default);
          data.splice(data.indexOf(defaultPage), 1);
          data.unshift(defaultPage);
        }

        return {
          id: index + 1,
          title: category,
          data,
          // weight: level1Map[category],
        };
      });

      // sort first level elements (main categories)
      accordData.sort((a, b) => {
        const aWeight = level1Map[a.title];
        const bWeight = level1Map[b.title];
        if (aWeight === undefined) return 1;
        if (bWeight === undefined) return -1;
        return parseFloat(aWeight) - parseFloat(bWeight);
      });


      return accordData;
    } catch (error) {
      console.error(error);
    }
  }, [extractOrderingLevels, language]);

  const {
    data: helpPages,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ["help-pages", language], // only fetch data if language changes
    queryFn: fetchData,
    placeholderData: [],
    refetchOnWindowFocus: false,
  });

  const dependencyArray = useMemo(() => [nid, helpOpened], [nid, helpOpened]);

  useSuperDuperReliableSmoothScroll(dependencyArray, "palidzibas-content");
  if (isError)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <ErrorMessage text={error.error ?? t("dataFailedToLoad")} />
        <ReloadPageButton />
      </div>
    );

  return (
    <>
      <Banner bannerData={bannerData} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="palidzibas-main" id="palidzibas-main">
          <div>
            <div className="palidzibas-main-accordion">
              <Accordination
                helpPages={helpPages}
                tabOpened={tabOpened}
                helpOpened={helpOpened}
                setHelpOpened={setHelpOpened}
                setTabOpened={setTabOpened}
              />
            </div>
          </div>
          <div>
            <div className="help-search-wrapper">
              <CatalogSearch
                placeholder={t("helpSpecifySearchContent")}
                keywords
                manualInput={searchParams.get("searchWord")}
                setManualInput={(val) => {
                  handleSearchWordChange(val);
                }}
              />
            </div>
            {isFetching ? (
              <Loader />
            ) : (
              <div className="palidzibas-main-right" id="palidzibas-content">
                <div className="palidzibas-main-right-width">
                  <span className="palidzibas-main-right-width-font1">
                    {selectedTitle}
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: highlightKeywordHelp(
                          selectedTitle,
                          searchParams.get("searchWord"),
                          foregroundColor,
                          backgroundColor
                        ),
                      }}
                    /> */}
                  </span>
                  {selectedBody && (
                    <div
                      className="palidzibas-main-right-width-font2"
                      dangerouslySetInnerHTML={{
                        __html: selectedBody,
                      }}
                    />
                  )}
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: highlightKeywordHelp(
                        selectedBody,
                        searchParams.get("searchWord"),
                        foregroundColor,
                        backgroundColor
                      ),
                    }}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Help;
