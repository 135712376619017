export const countries = [
  {value: 'AF', label: 'Afganistāna', labelEn: 'Afghanistan'},
  {value: 'AL', label: 'Albānija', labelEn: 'Albania'},
  {value: 'DZ', label: 'Alžīrija', labelEn: 'Algeria'},
  {value: 'US', label: 'Amerikas Savienotās Valstis', labelEn: 'United States'},
  {value: 'AD', label: 'Andora', labelEn: 'Andorra'},
  {value: 'AI', label: 'Angilja', labelEn: 'Anguilla'},
  {value: 'AO', label: 'Angola', labelEn: 'Angola'},
  {value: 'AQ', label: 'Antarktika', labelEn: 'Antarctica'},
  {value: 'AG', label: 'Antigva un Barbuda', labelEn: 'Antigua and Barbuda'},
  {value: 'GB', label: 'Apvienotā Karaliste', labelEn: 'United Kingdom'},
  {value: 'AE', label: 'Apvienotie Arābu Emirāti', labelEn: 'United Arab Emirates'},
  {value: 'AR', label: 'Argentīna', labelEn: 'Argentina'},
  {value: 'AM', label: 'Armēnija', labelEn: 'Armenia'},
  {value: 'AW', label: 'Aruba', labelEn: 'Aruba'},
  {value: 'UM', label: 'ASV Mazās Aizjūras salas', labelEn: 'United States Minor Outlying Islands'},
  {value: 'AS', label: 'ASV Samoa', labelEn: 'American Samoa'},
  {value: 'VI', label: 'ASV Virdžīnas', labelEn: 'United States Virgin Islands'},
  {value: 'AU', label: 'Austrālija', labelEn: 'Australia'},
  {value: 'AT', label: 'Austrija', labelEn: 'Austria'},
  {value: 'TL', label: 'Austrumtimora', labelEn: 'Timor-Leste'},
  {value: 'AZ', label: 'Azerbaidžāna', labelEn: 'Azerbaijan'},
  {value: 'BS', label: 'Bahamu salas', labelEn: 'Bahamas'},
  {value: 'BH', label: 'Bahreina', labelEn: 'Bahrain'},
  {value: 'BY', label: 'Baltkrievija', labelEn: 'Belarus'},
  {value: 'BD', label: 'Bangladeša', labelEn: 'Bangladesh'},
  {value: 'BB', label: 'Barbadosa', labelEn: 'Barbados'},
  {value: 'BZ', label: 'Beliza', labelEn: 'Belize'},
  {value: 'BE', label: 'Beļģija', labelEn: 'Belgium'},
  {value: 'BJ', label: 'Benina', labelEn: 'Benin'},
  {value: 'BM', label: 'Bermudu salas', labelEn: 'Bermuda'},
  {value: 'BO', label: 'Bolīvija', labelEn: 'Bolivia'},
  {value: 'BA', label: 'Bosnija un Hercegovina', labelEn: 'Bosnia and Herzegovina'},
  {value: 'BW', label: 'Botsvāna', labelEn: 'Botswana'},
  {value: 'BR', label: 'Brazīlija', labelEn: 'Brazil'},
  {value: 'VG', label: 'Britu Virdžīnas', labelEn: 'British Virgin Islands'},
  {value: 'BN', label: 'Bruneja', labelEn: 'Brunei'},
  {value: 'BG', label: 'Bulgārija', labelEn: 'Bulgaria'},
  {value: 'BF', label: 'Burkinafaso', labelEn: 'Burkina Faso'},
  {value: 'BI', label: 'Burundija', labelEn: 'Burundi'},
  {value: 'BT', label: 'Butāna', labelEn: 'Bhutan'},
  {value: 'BV', label: 'Buvē sala', labelEn: 'Bouvet Island'},
  {value: 'CF', label: 'Centrālāfrikas Republika', labelEn: 'Central African Republic'},
  {value: 'TD', label: 'Čada', labelEn: 'Chad'},
  {value: 'CZ', label: 'Čehija', labelEn: 'Czech Republic'},
  {value: 'CL', label: 'Čīle', labelEn: 'Chile'},
  {value: 'DK', label: 'Dānija', labelEn: 'Denmark'},
  {value: 'ZA', label: 'Dienvidāfrikas Republika', labelEn: 'South Africa'},
  {value: 'GS', label: 'Dienviddžordžija un Dienvidsendviču salas', labelEn: 'South Georgia and the South Sandwich Islands'},
  {value: 'KR', label: 'Dienvidkoreja', labelEn: 'South Korea'},
  {value: 'SS', label: 'Dienvidsudāna', labelEn: 'South Sudan'},
  {value: 'DM', label: 'Dominika', labelEn: 'Dominica'},
  {value: 'DO', label: 'Dominikāna', labelEn: 'Dominican Republic'},
  {value: 'JE', label: 'Džērsija', labelEn: 'Jersey'},
  {value: 'DJ', label: 'Džibutija', labelEn: 'Djibouti'},
  {value: 'EG', label: 'Ēģipte', labelEn: 'Egypt'},
  {value: 'EC', label: 'Ekvadora', labelEn: 'Ecuador'},
  {value: 'GQ', label: 'Ekvatoriālā Gvineja', labelEn: 'Equatorial Guinea'},
  {value: 'ER', label: 'Eritreja', labelEn: 'Eritrea'},
  {value: 'ET', label: 'Etiopija', labelEn: 'Ethiopia'},
  {value: 'FO', label: 'Fēru salas', labelEn: 'Faroe Islands'},
  {value: 'FJ', label: 'Fidži', labelEn: 'Fiji'},
  {value: 'PH', label: 'Filipīnas', labelEn: 'Philippines'},
  {value: 'FK', label: 'Folklenda salas', labelEn: 'Falkland Islands'},
  {value: 'FR', label: 'Francija', labelEn: 'France'},
  {value: 'TF', label: 'Francijas Dienvidjūru teritorija', labelEn: 'French Southern Territories'},
  {value: 'GF', label: 'Francijas Gviāna', labelEn: 'French Guiana'},
  {value: 'PF', label: 'Francijas Polinēzija', labelEn: 'French Polynesia'},
  {value: 'GA', label: 'Gabona', labelEn: 'Gabon'},
  {value: 'GY', label: 'Gajāna', labelEn: 'Guyana'},
  {value: 'GM', label: 'Gambija', labelEn: 'Gambia'},
  {value: 'GH', label: 'Gana', labelEn: 'Ghana'},
  {value: 'GG', label: 'Gērnsija', labelEn: 'Guernsey'},
  {value: 'GI', label: 'Gibraltārs', labelEn: 'Gibraltar'},
  {value: 'GD', label: 'Grenāda', labelEn: 'Grenada'},
  {value: 'GL', label: 'Grenlande', labelEn: 'Greenland'},
  {value: 'GR', label: 'Grieķija', labelEn: 'Greece'},
  {value: 'GE', label: 'Gruzija', labelEn: 'Georgia'},
  {value: 'GU', label: 'Guama', labelEn: 'Guam'},
  {value: 'GP', label: 'Gvadelupa', labelEn: 'Guadeloupe'},
  {value: 'GT', label: 'Gvatemala', labelEn: 'Guatemala'},
  {value: 'GN', label: 'Gvineja', labelEn: 'Guinea'},
  {value: 'GW', label: 'Gvineja-Bisava', labelEn: 'Guinea-Bissau'},
  {value: 'HT', label: 'Haiti', labelEn: 'Haiti'},
  {value: 'HM', label: 'Hērda sala un Makdonalda salas', labelEn: 'Heard Island and McDonald Islands'},
  {value: 'HN', label: 'Hondurasa', labelEn: 'Honduras'},
  {value: 'HR', label: 'Horvātija', labelEn: 'Croatia'},
  {value: 'EE', label: 'Igaunija', labelEn: 'Estonia'},
  {value: 'IN', label: 'Indija', labelEn: 'India'},
  {value: 'IO', label: 'Indijas okeāna Britu teritorija', labelEn: 'British Indian Ocean Territory'},
  {value: 'ID', label: 'Indonēzija', labelEn: 'Indonesia'},
  {value: 'IQ', label: 'Irāka', labelEn: 'Iraq'},
  {value: 'IR', label: 'Irāna', labelEn: 'Iran'},
  {value: 'IE', label: 'Īrija', labelEn: 'Ireland'},
  {value: 'IS', label: 'Islande', labelEn: 'Iceland'},
  {value: 'IT', label: 'Itālija', labelEn: 'Italy'},
  {value: 'IL', label: 'Izraēla', labelEn: 'Israel'},
  {value: 'JM', label: 'Jamaika', labelEn: 'Jamaica'},
  {value: 'JP', label: 'Japāna', labelEn: 'Japan'},
  {value: 'NC', label: 'Jaunkaledonija', labelEn: 'New Caledonia'},
  {value: 'NZ', label: 'Jaunzēlande', labelEn: 'New Zealand'},
  {value: 'YE', label: 'Jemena', labelEn: 'Yemen'},
  {value: 'JO', label: 'Jordānija', labelEn: 'Jordan'},
  {value: 'CV', label: 'Kaboverde', labelEn: 'Cape Verde'},
  {value: 'KY', label: 'Kaimanu salas', labelEn: 'Cayman Islands'},
  {value: 'KH', label: 'Kambodža', labelEn: 'Cambodia'},
  {value: 'CM', label: 'Kamerūna', labelEn: 'Cameroon'},
  {value: 'CA', label: 'Kanāda', labelEn: 'Canada'},
  {value: 'QA', label: 'Katara', labelEn: 'Qatar'},
  {value: 'KZ', label: 'Kazahstāna', labelEn: 'Kazakhstan'},
  {value: 'KE', label: 'Kenija', labelEn: 'Kenya'},
  {value: 'CY', label: 'Kipra', labelEn: 'Cyprus'},
  {value: 'CW', label: 'Kirasao', labelEn: 'Curaçao'},
  {value: 'KG', label: 'Kirgizstāna', labelEn: 'Kyrgyzstan'},
  {value: 'KI', label: 'Kiribati', labelEn: 'Kiribati'},
  {value: 'CC', label: 'Kokosu (Kīlinga) salas', labelEn: 'Cocos (Keeling) Islands'},
  {value: 'CO', label: 'Kolumbija', labelEn: 'Colombia'},
  {value: 'KM', label: 'Komoru salas', labelEn: 'Comoros'},
  {value: 'CG', label: 'Kongo (Brazavila)', labelEn: 'Congo (Brazzaville)'},
  {value: 'CD', label: 'Kongo (Kinšasa)', labelEn: 'Congo (Kinshasa)'},
  {value: 'CR', label: 'Kostarika', labelEn: 'Costa Rica'},
  {value: 'CI', label: 'Kotdivuāra', labelEn: 'Ivory Coast'},
  {value: 'RU', label: 'Krievija', labelEn: 'Russia'},
  {value: 'CU', label: 'Kuba', labelEn: 'Cuba'},
  {value: 'CK', label: 'Kuka salas', labelEn: 'Cook Islands'},
  {value: 'KW', label: 'Kuveita', labelEn: 'Kuwait'},
  {value: 'CN', label: 'Ķīna', labelEn: 'China'},
  {value: 'HK', label: 'Ķīnas īpašās pārvaldes apgabals Honkonga', labelEn: 'Hong Kong'},
  {value: 'MO', label: 'ĶTR īpašais administratīvais reģions Makao', labelEn: 'Macau'},
  {value: 'LA', label: 'Laosa', labelEn: 'Laos'},
  {value: 'LV', label: 'Latvija', labelEn: 'Latvia'},
  {value: 'LS', label: 'Lesoto', labelEn: 'Lesotho'},
  {value: 'LB', label: 'Libāna', labelEn: 'Lebanon'},
  {value: 'LR', label: 'Libērija', labelEn: 'Liberia'},
  {value: 'LY', label: 'Lībija', labelEn: 'Libya'},
  {value: 'LT', label: 'Lietuva', labelEn: 'Lithuania'},
  {value: 'LI', label: 'Lihtenšteina', labelEn: 'Liechtenstein'},
  {value: 'LU', label: 'Luksemburga', labelEn: 'Luxembourg'},
  {value: 'MG', label: 'Madagaskara', labelEn: 'Madagascar'},
  {value: 'YT', label: 'Majota', labelEn: 'Mayotte'},
  {value: 'MY', label: 'Malaizija', labelEn: 'Malaysia'},
  {value: 'MW', label: 'Malāvija', labelEn: 'Malawi'},
  {value: 'MV', label: 'Maldīvija', labelEn: 'Maldives'},
  {value: 'ML', label: 'Mali', labelEn: 'Mali'},
  {value: 'MT', label: 'Malta', labelEn: 'Malta'},
  {value: 'MA', label: 'Maroka', labelEn: 'Morocco'},
  {value: 'MH', label: 'Māršala salas', labelEn: 'Marshall Islands'},
  {value: 'MQ', label: 'Martinika', labelEn: 'Martinique'},
  {value: 'MU', label: 'Maurīcija', labelEn: 'Mauritius'},
  {value: 'MR', label: 'Mauritānija', labelEn: 'Mauritania'},
  {value: 'MX', label: 'Meksika', labelEn: 'Mexico'},
  {value: 'ME', label: 'Melnkalne', labelEn: 'Montenegro'},
  {value: 'IM', label: 'Menas sala', labelEn: 'Isle of Man'},
  {value: 'FM', label: 'Mikronēzija', labelEn: 'Micronesia'},
  {value: 'MM', label: 'Mjanma (Birma)', labelEn: 'Myanmar (Burma)'},
  {value: 'MD', label: 'Moldova', labelEn: 'Moldova'},
  {value: 'MC', label: 'Monako', labelEn: 'Monaco'},
  {value: 'MN', label: 'Mongolija', labelEn: 'Mongolia'},
  {value: 'MS', label: 'Montserrata', labelEn: 'Montserrat'},
  {value: 'MZ', label: 'Mozambika', labelEn: 'Mozambique'},
  {value: 'NA', label: 'Namībija', labelEn: 'Namibia'},
  {value: 'NR', label: 'Nauru', labelEn: 'Nauru'},
  {value: 'NP', label: 'Nepāla', labelEn: 'Nepal'},
  {value: 'NL', label: 'Nīderlande', labelEn: 'Netherlands'},
  {value: 'BQ', label: 'Nīderlandes Karību salas', labelEn: 'Caribbean Netherlands'},
  {value: 'NE', label: 'Nigēra', labelEn: 'Niger'},
  {value: 'NG', label: 'Nigērija', labelEn: 'Nigeria'},
  {value: 'NI', label: 'Nikaragva', labelEn: 'Nicaragua'},
  {value: 'NU', label: 'Niue', labelEn: 'Niue'},
  {value: 'NF', label: 'Norfolkas sala', labelEn: 'Norfolk Island'},
  {value: 'NO', label: 'Norvēģija', labelEn: 'Norway'},
  {value: 'AX', label: 'Olandes salas', labelEn: 'Åland Islands'},
  {value: 'OM', label: 'Omāna', labelEn: 'Oman'},
  {value: 'PK', label: 'Pakistāna', labelEn: 'Pakistan'},
  {value: 'PW', label: 'Palau', labelEn: 'Palau'},
  {value: 'PS', label: 'Palestīnas teritorijas', labelEn: 'Palestinian Territories'},
  {value: 'PA', label: 'Panama', labelEn: 'Panama'},
  {value: 'PG', label: 'Papua-Jaungvineja', labelEn: 'Papua New Guinea'},
  {value: 'PY', label: 'Paragvaja', labelEn: 'Paraguay'},
  {value: 'PE', label: 'Peru', labelEn: 'Peru'},
  {value: 'PN', label: 'Pitkērnas salas', labelEn: 'Pitcairn Islands'},
  {value: 'PL', label: 'Polija', labelEn: 'Poland'},
  {value: 'PT', label: 'Portugāle', labelEn: 'Portugal'},
  {value: 'PR', label: 'Puertoriko', labelEn: 'Puerto Rico'},
  {value: 'RE', label: 'Reinjona', labelEn: 'Réunion'},
  {value: 'EH', label: 'Rietumsahāra', labelEn: 'Western Sahara'},
  {value: 'RW', label: 'Ruanda', labelEn: 'Rwanda'},
  {value: 'RO', label: 'Rumānija', labelEn: 'Romania'},
  {value: 'SV', label: 'Salvadora', labelEn: 'El Salvador'},
  {value: 'WS', label: 'Samoa', labelEn: 'Samoa'},
  {value: 'SM', label: 'Sanmarīno', labelEn: 'San Marino'},
  {value: 'ST', label: 'Santome un Prinsipi', labelEn: 'São Tomé and Príncipe'},
  {value: 'SA', label: 'Saūda Arābija', labelEn: 'Saudi Arabia'},
  {value: 'SC', label: 'Seišelu salas', labelEn: 'Seychelles'},
  {value: 'BL', label: 'Senbartelmī', labelEn: 'Saint Barthélemy'},
  {value: 'SN', label: 'Senegāla', labelEn: 'Senegal'},
  {value: 'MF', label: 'Senmartēna', labelEn: 'Saint Martin'},
  {value: 'PM', label: 'Senpjēra un Mikelona', labelEn: 'Saint Pierre and Miquelon'},
  {value: 'KN', label: 'Sentkitsa un Nevisa', labelEn: 'Saint Kitts and Nevis'},
  {value: 'LC', label: 'Sentlūsija', labelEn: 'Saint Lucia'},
  {value: 'VC', label: 'Sentvinsenta un Grenadīnas', labelEn: 'Saint Vincent and the Grenadines'},
  {value: 'RS', label: 'Serbija', labelEn: 'Serbia'},
  {value: 'SG', label: 'Singapūra', labelEn: 'Singapore'},
  {value: 'SX', label: 'Sintmārtena', labelEn: 'Sint Maarten'},
  {value: 'SY', label: 'Sīrija', labelEn: 'Syria'},
  {value: 'SL', label: 'Sjerraleone', labelEn: 'Sierra Leone'},
  {value: 'SK', label: 'Slovākija', labelEn: 'Slovakia'},
  {value: 'SI', label: 'Slovēnija', labelEn: 'Slovenia'},
  {value: 'SO', label: 'Somālija', labelEn: 'Somalia'},
  {value: 'FI', label: 'Somija', labelEn: 'Finland'},
  {value: 'ES', label: 'Spānija', labelEn: 'Spain'},
  {value: 'SD', label: 'Sudāna', labelEn: 'Sudan'},
  {value: 'SR', label: 'Surinama', labelEn: 'Suriname'},
  {value: 'SH', label: 'Sv.Helēnas sala', labelEn: 'Saint Helena'},
  {value: 'SJ', label: 'Svalbāra un Jana Majena sala', labelEn: 'Svalbard and Jan Mayen'},
  {value: 'SZ', label: 'Svatini', labelEn: 'Eswatini'},
  {value: 'LK', label: 'Šrilanka', labelEn: 'Sri Lanka'},
  {value: 'CH', label: 'Šveice', labelEn: 'Switzerland'},
  {value: 'TJ', label: 'Tadžikistāna', labelEn: 'Tajikistan'},
  {value: 'TW', label: 'Taivāna', labelEn: 'Taiwan'},
  {value: 'TH', label: 'Taizeme', labelEn: 'Thailand'},
  {value: 'TZ', label: 'Tanzānija', labelEn: 'Tanzania'},
  {value: 'TC', label: 'Tērksas un Kaikosas salas', labelEn: 'Turks and Caicos Islands'},
  {value: 'TG', label: 'Togo', labelEn: 'Togo'},
  {value: 'TK', label: 'Tokelau', labelEn: 'Tokelau'},
  {value: 'TO', label: 'Tonga', labelEn: 'Tonga'},
  {value: 'TT', label: 'Trinidāda un Tobāgo', labelEn: 'Trinidad and Tobago'},
  {value: 'TN', label: 'Tunisija', labelEn: 'Tunisia'},
  {value: 'TR', label: 'Turcija', labelEn: 'Turkey'},
  {value: 'TM', label: 'Turkmenistāna', labelEn: 'Turkmenistan'},
  {value: 'TV', label: 'Tuvalu', labelEn: 'Tuvalu'},
  {value: 'UG', label: 'Uganda', labelEn: 'Uganda'},
  {value: 'UA', label: 'Ukraina', labelEn: 'Ukraine'},
  {value: 'HU', label: 'Ungārija', labelEn: 'Hungary'},
  {value: 'UY', label: 'Urugvaja', labelEn: 'Uruguay'},
  {value: 'UZ', label: 'Uzbekistāna', labelEn: 'Uzbekistan'},
  {value: 'DE', label: 'Vācija', labelEn: 'Germany'},
  {value: 'VU', label: 'Vanuatu', labelEn: 'Vanuatu'},
  {value: 'VA', label: 'Vatikāns', labelEn: 'Vatican City'},
  {value: 'VE', label: 'Venecuēla', labelEn: 'Venezuela'},
  {value: 'VN', label: 'Vjetnama', labelEn: 'Vietnam'},
  {value: 'WF', label: 'Volisa un Futunas salas', labelEn: 'Wallis and Futuna'},
  {value: 'SB', label: 'Zālamana salas', labelEn: 'Solomon Islands'},
  {value: 'ZM', label: 'Zambija', labelEn: 'Zambia'},
  {value: 'KP', label: 'Ziemeļkoreja', labelEn: 'North Korea'},
  {value: 'MK', label: 'Ziemeļmaķedonija', labelEn: 'North Macedonia'},
  {value: 'MP', label: 'Ziemeļu Marianas salas', labelEn: 'Northern Mariana Islands'},
  {value: 'CX', label: 'Ziemsvētku sala', labelEn: 'Christmas Island'},
  {value: 'ZW', label: 'Zimbabve', labelEn: 'Zimbabwe'},
  {value: 'SE', label: 'Zviedrija', labelEn: 'Sweden'},
]
