import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalComponent from "./modal";
import { StateContext } from "../context";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { auth } from "../classes/auth";
import { usr } from "../classes/user";
import { t } from "i18next";
import { NotificationBanner } from "../common";

const AuthModal = ({ trigger, onRegisterCallback = null }) => {
  const { handleModalClose, modalSettings } = useContext(StateContext);
  const redirectTo = (modalSettings && modalSettings.props && modalSettings.props.redirectTo) ?? "/dashboard";
  const [authFailed, setAuthFailed] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({ mode: `all` });

  const authorize = async (body) => {
    setAuthFailed(false);
    try {
      await auth.login(body);
    } catch (e) {
      console.error(e);
    }
    if (auth.isAuthorized()) {
      await usr.refresh(() => {
        navigate(redirectTo);
        window.scrollTo({ top: 0, behavior: "smooth" });
        handleModalClose("auth");
      });
    } else {
      setAuthFailed(true);
      console.error(`Neizdevās autorizēties.`);
    }
  };

  const Content = () => {
    return (
      <div>
        <div className="authorize-main">
          <div className="authorize-main-left w3-col">
            <span className="authorize-main-left-title">
              <Trans i18nKey="popupMessageRegisteredUser" />
            </span>
            {Boolean(authFailed) && (
              <NotificationBanner
                text={t("wrongUsernameOrPassword")}
                backgroundColor="#9e313a"
                canClose={true}
              />
            )}
            <form onSubmit={handleSubmit((data) => authorize({ ...data, token: window.reCaptchaToken }))}>
              <div className="authorize-main-left-btngroup">
                <input
                  className={`authorize-main-left-btngroup-form focus-red${
                    Boolean(authFailed) ? " error" : ""
                  }`}
                  placeholder={t("popupMessageEmail")}
                  {...register(`username`, {
                    required: { message: "fieldIsRequired", value: true },
                  })}
                />

                <input
                  className="authorize-main-left-btngroup-form1 focus-red"
                  placeholder={t("popupMessagePassword")}
                  type="password"
                  {...register(`password`, {
                    required: { message: "fieldIsRequired", value: true },
                  })}
                />
              </div>
              <GoogleReCaptcha
                onVerify={(token) => {
                  window.reCaptchaToken = token;
                }}
                refreshReCaptcha={refreshReCaptcha}
              />
              <button className="authorize-main-left-reg focus-red">
                <img alt="alt" className="authorize-main-left-reg-icon" src="/assets/img/exit.svg" />
                <span className="authorize-main-left-reg-text">
                  <Trans i18nKey="popupMessageLogIn" />
                </span>
              </button>
            </form>

            <span className="authorize-main-left-cookies-rules">
              {t("theSiteIsProtected")}
              <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                {/* privātuma politika */}
                {t("privacyPolicy")}
              </a>{" "}
              {t("theSiteIsProtectedAnd")}
              <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                {t("rules")}
              </a>
              .
            </span>

            <div className="authorize-main-left-linkform" style={{ marginTop: "1rem" }}>
              <span className="authorize-main-left-linkform-text">
                <Trans i18nKey="popupMessageHaventRegistered" />
              </span>
              <div
                className="authorize-main-left-linkform-link focus-red"
                tabIndex={0}
                onClick={() => {
                  window.localStorage.regKey = null;
                  window.localStorage.type = null;
                  navigate("/register");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  handleModalClose("auth");
                  if (onRegisterCallback) {
                    onRegisterCallback();
                  }
                }}
                style={{ cursor: "pointer" }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    window.localStorage.regKey = null;
                    window.localStorage.type = null;
                    navigate("/register");
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    handleModalClose();
                    if (onRegisterCallback) {
                      onRegisterCallback();
                    }
                  }
                }}
              >
                <img
                  alt="alt"
                  className="authorize-main-left-linkform-link-icon"
                  src="/assets/img/next.svg"
                />
                <span className="authorize-main-left-linkform-link-text">
                  <Trans i18nKey="titleCreateProfile" />
                </span>
              </div>
            </div>
            <div className="authorize-main-left-linkform">
              <span className="authorize-main-left-linkform-text">
                <Trans i18nKey="popupMessageForgotPassword" />
              </span>
              <div
                style={{ cursor: "pointer" }}
                className="authorize-main-left-linkform-link focus-red"
                tabIndex={0}
                onClick={() => {
                  navigate("/passwordreset");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  handleModalClose();
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    navigate("/passwordreset");
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    handleModalClose();
                  }
                }}
              >
                <img
                  alt="alt"
                  className="authorize-main-left-linkform-link-icon"
                  src="/assets/img/next.svg"
                />
                <span className="authorize-main-left-linkform-link-text">
                  <Trans i18nKey="generalRenewPassword" />
                </span>
              </div>
            </div>
          </div>
          <div className="authorize-main-right w3-col">
            <img alt="alt" className="authorize-main-right-bk" src="/assets/img/authbk.png" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalComponent id="auth" className="authorize" trigger={trigger} title={t("auth")}>
      <Content />
    </ModalComponent>
  );
};

export default AuthModal;
