const LabelTag = ({
  text,
  setText,
  onRemove,
  forceNotHovered,
  isInteractable = true,
  isDisabled = false,
}) => {
  const onRemoveLocal = () => {
    if (isDisabled) return;
    onRemove();
  };

  return (
    <label className="label focus-red">
      <p
        className={`font ${!isInteractable ? "label-catalog-font" : ""}`}
        style={{ opacity: isDisabled ? 0.7 : 1 }}
      >
        {text}
      </p>
      {isInteractable && (
        <div className="focus-red">
          <img
            alt="remove input"
            onClick={onRemoveLocal}
            className="focus-red label-tag-close"
            src={"/assets/img/close.svg"}
            style={{ verticalAlign: "baseline", cursor: isDisabled ? "not-allowed" : "pointer" }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onRemoveLocal();
              }
            }}
          />
        </div>
      )}
    </label>
  );
};

export default LabelTag;
